import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const ReposicionPartidasPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Rectificación y reposición de partidas" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Rectificación y reposición de partidas"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Rectificación y reposición de partidas"
      >
        <p>
        El fundamento legal lo encontramos el artículo 33 del <strong>ACUERDO DEL DIRECTORIO NÚMERO 176-2008 </strong> 
         que se refiere a <strong>REGLAMENTO DE INSCRIPCIONES DEL REGISTRO CIVIL DE LAS PERSONAS</strong> de la República
        de Guatemala.
        </p>        
        <p>
        La reposición de una inscripción, será repuesta por la vía Notarial o Judicial, debiendo para
        el efecto, los Registradores Civiles, asentar la inscripción que se pretende reponer, indicando
        en la misma los datos regístrales de la inscripción que se repone por este acto.
          </p>
          <p>
          <strong>¡¡¡Llámenos y haga su cita hoy!!!</strong>
            </p>
            <p>
            El licenciado Erick Claveria gustosamente le atenderá.
              </p>       
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default ReposicionPartidasPage

